<template>
  <aside id="page-sidebar" :class="{expand: isExpand}">
    <div id="logo">
      <a href="/" title="DERMAP" >
        <BaseLogo :showSymbol="isExpand" />
      </a>
    </div>
    
    <nav class="nav">
      <ul>
        <li>
          <router-link to="/" class="nav-menu" title="Home">
            <span class="nav-icon">
              <BaseIcon iconName="home" />
            </span>
            <span class="nav-text">{{ t('common.Home') }}</span>
          </router-link>
        </li>
        <li>
          <router-link to="/patient" class="nav-menu" title="Patient List">
            <span class="nav-icon">
              <BaseIcon iconName="patient" />
            </span>
            <span class="nav-text">{{ t('common.PatientList') }}</span>
          </router-link>
        </li>
        <!-- 24.04.25 요청사항 반영 : Pathology 관련 기능 disabled 처리 (수정자 : 배진웅)-->
        <!-- <li>
          <router-link to="/pathology" class="nav-menu" title="Pathology">
            <span class="nav-icon">
              <BaseIcon iconName="pathology" />
            </span>
            <span class="nav-text">{{ t('common.Pathology') }}</span>
          </router-link>
        </li> -->
        <li>
          <router-link to="/statistics" class="nav-menu" title="Statistics">
            <span class="nav-icon">
              <BaseIcon iconName="statistics" />
            </span>
            <span class="nav-text">{{ t('common.Statistics') }}</span>
          </router-link>
        </li>
        <li class="nav-over" v-if="!!doctor?.adminFlag">
          <button class="nav-menu" :class="{'router-link-exact-active': route.path.includes('/setting/')}">
            <span class="nav-icon">
              <BaseIcon iconName="setting" />
            </span>
            <span class="nav-text">{{ t('common.Setting') }}</span>
          </button>
          <ul class="nav-sub">
            <li>
              <router-link to="/setting/clinic" class="nav-sub-menu" title="Clinic Preferences">
                {{ t('common.ClinicPreferences') }}
              </router-link>
            </li>
            <li>
              <router-link to="/setting" class="nav-sub-menu" title="Preferences">
                {{ t('common.Preferences') }}
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-over" v-else>
          <router-link to="/setting" class="nav-menu" title="Preferences">
            <span class="nav-icon">
              <BaseIcon iconName="setting" />
            </span>
            <span class="nav-text">{{ t('common.Setting') }}</span>
          </router-link>
        </li>
        <!-- <li>
          <router-link to="/activites" class="nav-menu" title="Activites">
            <span class="nav-icon">
              <BaseIcon iconName="activites" />
            </span>
            <span class="nav-text">Activites</span>
          </router-link>
        </li>
        <li>
          <router-link to="/feedback" class="nav-menu" title="Feedback">
            <span class="nav-icon">
              <BaseIcon iconName="feedback" />
            </span>
            <span class="nav-text">Feedback</span>
          </router-link>
        </li>
        <li>
          <router-link to="/news" class="nav-menu" title="News">
            <span class="nav-icon">
              <BaseIcon iconName="news" />
            </span>
            <span class="nav-text">News</span>
          </router-link>
        </li> -->
      </ul>
    </nav>

    <button @click="logOut" class="button-logout" title="Logout">
      <BaseIcon iconName="logout" />
      <span class="logout-text">{{ t('button.Logout') }}</span>
    </button>

    <button @click="toggle" class="button-toggle">
      <BaseIcon iconName="arrowDoubleRight" />
    </button>
  </aside>
</template>

<script setup>
//vue import
import { ref } from 'vue';
//lib import
import { useRoute } from 'vue-router';
import router from '@/router';

//components import
import BaseLogo from '@/components/common/base/BaseLogo.vue';

//store import
import { storeToRefs } from "pinia";
import { useDoctorStore } from "@/stores/store.doctor";
import { useMainStore } from "@/stores";

//store
const { resetForLogout } = useMainStore();
const { doctor } = storeToRefs(useDoctorStore());

const route = useRoute();
const isExpand = ref(false);
const toggle = async () => {
  isExpand.value = !isExpand.value;
}

const logOut = async () => {
  await resetForLogout();

  router.push('/login');
}


</script>

<style lang="scss" scoped>
#page-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 72px;
  height: 100%;
  min-height: 800px;
  background-color: var(--box-color-background);
  transition: width .3s;
  border-right: 1px solid var(--box-color-border);
  filter: drop-shadow(0px 0px 10px var(--box-color-shadow));
  z-index: 999;
  &.expand {
    width: 300px;
    .nav-sub {
      position: relative;
      left: auto;
      width: auto;
      margin-top: 10px;
    }
    .nav-sub-menu {
      padding-left: 68px;
    }
    .nav-text {
      opacity: 1;
    }
    .logout-text {
      opacity: 1;
    }
    .button-logout {
      width: auto;
    }
    .button-toggle {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

#logo {
  position: relative;
  display: flex;
  align-items: center;
  height: 104px;
  padding: 0 24px;
  transition: height .3s;
  overflow: hidden;
  a {
    color: var(--font-color-primary);
  }
}

.nav {
  position: relative;
  display: block !important;
  width: 100%;
  padding: 18px 0;
  // overflow: hidden;
  z-index: 3;
  li {
    position: relative;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    li {
      margin-top: 0;
    }
  }
}

.nav-menu {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 0 25px;
  font-size: 18px;
  color: var(--font-color-primary);
  line-height: 18px;
  text-decoration: none;
  overflow: hidden;
  &:hover {
    color: var(--font-color-primary);
    background-color: var(--box-color-border);
  }
  &.active,
  &.router-link-exact-active {
    color: var(--font-color-active);
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      height: 32px;
      width: 4px;
      border-radius: 4px 0 0 4px;
      transform: translate(0,-50%);
      background: #F39828;
    }
  }
}
.nav-over:hover {
  .nav-sub {
    display: block;
  }
}
.nav-sub {
  position: absolute;
  top: 0;
  left: 72px;
  width: 200px;
  display: none;
  background-color: var(--box-color-background);
  overflow: hidden;
}
.nav-sub-menu {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 25px;
  font-size: 14px;
  color: var(--font-color-primary);
  &:hover {
    color: var(--font-color-primary);
    background-color: var(--box-color-border);
  }
  &.active,
  &.router-link-exact-active {
    color: var(--font-color-active);
  }
}
.nav-icon {
  display: inline-block;
  width: 22px;
  margin-right: 18px;
  text-align: center;
}

.nav-text {
  white-space: nowrap;
  transition: opacity 0.3s;
  opacity: 0;
}

.button-logout {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 72px;
  padding: 10px 25px;
  font-size: 18px;
  color: var(--font-color-secondary);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  svg {
    margin: 0 2px;
  }
  &:hover {
    color: var(--font-color-primary);
  }
}

.logout-text {
  margin-left: 5px;
  transition: opacity 0.3s;
  opacity: 0;
}

.button-toggle {
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 60px;
  margin-top: -30px;
  color: var(--font-color-primary);
  background-color: var(--box-color-background);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--box-color-border);
  border-left: 0;
  z-index: 1;
}
</style>