import { defineStore } from 'pinia';
import { PatientModel, PatientLogListModel, PatientStoreModel, SpotFollowUpModel, PatientConsentSign, PdfPatient } from '@/types/type.patient';
import PatientApi from '@/api/api.patient'
import { constant } from '@/common/constant';
import { setPatientFormat } from '@/controller/PatientController';
import { initPatientData } from '@/controller/DataController';

export const usePatientStore = defineStore('patient', {
  state: () : PatientStoreModel => ({
    patients: [],
    patientsMap: {},
    spotFollowUps: null,
    patientLogs: null,
    patient: initPatientData(),
    patientConsentSignList: [],
    pdfPatient: null,
    loading: false,
    error: null,
  }),

  getters: {
    getGender():string{
      type stringType = {
        [key:string]:string
      }
      const gender : stringType = {
        m: constant.SEX_FULLNAME_MALE,
        f: constant.SEX_FULLNAME_FEMALE,
        male: constant.SEX_FULLNAME_MALE,
        female: constant.SEX_FULLNAME_FEMALE,
        Male: constant.SEX_FULLNAME_MALE,
        Female: constant.SEX_FULLNAME_FEMALE
      };
      const p = this.patient as PatientModel;
      return gender[p.gender];
    },

    patientSeq():Number | null{
      return this.patient ? this.patient.patientSeq : null;
    }
  },

  actions: {

    /** 환자 추가 */
    async addPatient(patient: PatientModel):Promise<PatientModel> {
      const data = await PatientApi.addPatient(patient);
      return data;
    },

    /** 환자 리스트 가져오기 */
    async getPatients(clinicSeq:number=1):Promise<Array<PatientModel>> {
      const data = await PatientApi.getPatients(clinicSeq);
      this.patients = [];
      const map: {
        [seq:number]:PatientModel;
      }={}
      const results = data.map((result) => {
        result = setPatientFormat(result);
        map[result.patientSeq] = result;
        return result;
      });

      this.patientsMap = map;
      this.patients = results;
      return data;
    }, 

    /** 환자 가져오기 */
    async getPatient(patientSeq:number, isHasCheck?:boolean):Promise<PatientModel> {
      if(isHasCheck){
        if(this.patient){
          if(this.patient.firstName){
            return this.patient
          }
        }
      }
      const data = await PatientApi.getPatient(patientSeq);
      this.patient = null;
      this.patient = setPatientFormat(data);
      return data;
    }, 

    async getPatientLogs(index:number, size:number,patientSeq:number):Promise<PatientLogListModel> {
      const data = await PatientApi.getPatientLogs(index,size,patientSeq);
      this.patientLogs = [];
      this.patientLogs = data.resultList;
      return data;
    },

    async getFollowupList(patientSeq:number):Promise<Array<SpotFollowUpModel>> {
      const data = await PatientApi.getFollowupList(patientSeq);
      this.spotFollowUps = [];
      this.spotFollowUps = data;
      return data;
    },

    /** 환자 지우기 */
    async deletePatient(patient: PatientModel):Promise<PatientModel> {
      const data = await PatientApi.deletePatient(patient);
      return data;
    },

    /** 환자 아이디 저장 */
    activePatient(patient: PatientModel | null) {
      this.patient = patient;
      localStorage.setItem('patientSeq', JSON.stringify(patient?.patientSeq));
      // sessionStorage.setItem("patient", JSON.stringify(patient));
    },

    /** 환자 아이디 storage에서 가져와서 저장 */
    setPatient() {
      const storage = localStorage.getItem("patientSeq");
      if(storage){
        const patientSeq = JSON.parse(storage);
        const patient = this.patientsMap[patientSeq];
        if(patient){
          this.activePatient(patient);
        }
      }
    },

    /** 환자화면을 거치지않고 진입시 환자 정보를 찾는 함수 */
    findPatient(patientSeq:number) {
      const patient = this.patientsMap[patientSeq];
      if(patient){
        this.activePatient(patient);
      }
    },

    getPatientGender(seq:number):string{
      if(this.patientsMap && this.patientsMap[seq]){
        return this.patientsMap[seq].gender;
      }else{
        return constant.SEX_FULLNAME_MALE;
      }
    },

    async getConsentSignList(patientSeq:number):Promise<Array<PatientConsentSign>> {
      const data = await PatientApi.getConsentSignList(patientSeq);
      this.patientConsentSignList = data
      return data
    },

    async getPdfPatientByPatientSeq(patientSeq: number, startDate: string, endDate: string): Promise<PdfPatient> {
      const data = await PatientApi.getPdfPatientByPatientSeq(patientSeq, startDate, endDate);
      this.pdfPatient = data;
      return data;
    }
  }
});
