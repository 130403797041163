<template>
  <div id="layout-wrapper">
      <slot></slot>
  </div>
</template>

<script>

export default {
}
</script>