<template>
  <div class="avatar">
    <div class="picture" :class="{'contain': pictureHeight < pictureWidth}"
        @mouseover="isAddIcon = true" @mouseleave="isAddIcon = false">
      <div class="default-box" v-if="editable || !checkImage(picture)">
        <BaseIcon v-if="!checkImage(picture)" class="default-icon" iconName="person" ref="defaultIcon" />
        <BaseIcon v-if="editable" class="mouse-over-icon" v-show="isAddIcon" iconName="plus3" />
      </div>
      <img v-if="checkImage(picture)" :src="picture" alt="" @load="onImgLoad($event)" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

defineProps({
  picture:String,
  editable:Boolean
});
const pictureWidth = ref(null);
const pictureHeight = ref(null);
const isAddIcon = ref(false);
const defaultIcon = ref(null);

onMounted(() => {
  init();
})

const checkImage = (imageURL) => {
  // console.log("imageURL",imageURL);
  if(!imageURL || imageURL === '-' || imageURL === "EMPTY"){
    return false;
  }else{
    return true;
  }

}

const init = () => {
 }

const onImgLoad = (event) => {
  pictureWidth.value = event.target.width;
  pictureHeight.value = event.target.height;
}
</script>

<style lang="scss">
.avatar {
  position: relative;
  display: inline-block;
  line-height: 0;
  overflow: hidden;

  .picture {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--profile-background);
    border: 1px solid #848484;

    .default-box{
      width: 100%;
      height: 100%;

      .default-icon{
        position: absolute;
        left: 10%;
        top: 10%;
        color: #848484;
        fill-opacity: 0.1;
        width: 80%;
        height: 80%;
      }
      .mouse-over-icon{
        position: absolute;
        color: #848484;
        left: 33px;
        top:33px;
        cursor: pointer;
      }
    }

    img {
      max-width: 100%;
    }
    &.contain {
      img {
        max-width: none;
        max-height: 100%;
      }
    }

    
  }
  &-sm {
    .picture {
      width: 40px;
      height: 40px;
    }
  }
  &-md {
    .picture {
      width: 80px;
      height: 80px;
    }
  }
  &-lg {
    .picture {
      width: 125px;
      height: 125px;
    }
  }
  .file {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

</style>