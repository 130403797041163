<template>
  <div class="modal-feedback">
    <Form @submit="confirm">
      <div class="modal-body">
        <div class="modal-title">
          <h3 class="modal-h3">{{ t("common.Feedback") }}</h3>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label">{{ t("common.Type") }}</label>
            <Field v-model="type" class="form-select" name="type" as="select">
              <option value="" disabled selected>
                {{ t("common.Select") }}
              </option>
              <option :value="t('common.Suggestions')">
                {{ t("common.Suggestions") }}
              </option>
              <option :value="t('common.BugandErrorReporting')">
                {{ t("common.BugandErrorReporting") }}
              </option>
              <!-- <option :value="t('common.LaserDiagnosis')">
                {{ t("common.LaserDiagnosis") }}
              </option>
              <option :value="t('common.MembershopandInformation')">
                {{ t("common.MembershopandInformation") }}
              </option> -->
              <option :value="t('common.Other')">
                {{ t("common.Other") }}
              </option>
            </Field>
          </div>
          <div class="col-md-6">
            <label class="form-label">{{ t("common.Title") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="`${t('home.EnterTitle')}`"
              v-model="title"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <Field
              class="form-control memo"
              name="memo"
              as="textarea"
              :placeholder="`${t('common.EnterFeedback')}`"
              v-model="memo"
            />
          </div>
        </div>
      </div>
      <div class="modal-foot-buttons">
        <button @click="$emit('cancel')" type="button">
          {{ t("button.Cancel") }}
        </button>
        <button type="confirm" class="confirm" :disabled="!isChange">
          {{ t("button.Confirm") }}
        </button>
      </div>
    </Form>
  </div>
</template>

<script setup>
//import : vue
import { ref, watch, defineEmits } from "vue";

//import : api
import ClinicApi from "@/api/api.clinic";

//import : store
import { useDoctorStore } from "@/stores/store.doctor";

const { doctorSeq } = useDoctorStore();

const type = ref("");
const title = ref("");
const memo = ref("");
const isChange = ref(false);

const emit = defineEmits(["cancel"]);

watch(
  () => [type.value, title.value, memo.value],
  (val) => {
    if (val[0] && val[1] && val[2]) {
      isChange.value = true;
    } else {
      isChange.value = false;
    }
  }
);

const confirm = async () => {
  const payload = {
    title: title.value,
    bodyText: memo.value,
    type: type.value,
    doctorSeq: doctorSeq,
    webMobileType: "w",
  };
  await ClinicApi.addFeedback(payload);
  emit("cancel");
};
</script>

<style lang="scss">
.modal-feedback {
  width: 794px;

  .form-label {
    margin-bottom: 6px;
  }

  .memo {
    height: 280px;

    &::placeholder {
      color: var(--font-color-secondary);
    }
  }
}
</style>
