<template>
  <svg :class="{ active: !showSymbol }" class="logo-symbol" width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.84992 11.903C10.1368 11.903 12.8014 9.23838 12.8014 5.95147C12.8014 2.66456 10.1368 0 6.84992 0C3.56301 0 0.898438 2.66456 0.898438 5.95147C0.898438 9.23838 3.56301 11.903 6.84992 11.903Z" fill="currentColor"/>
    <path d="M29.1468 11.903C32.4337 11.903 35.0983 9.23838 35.0983 5.95147C35.0983 2.66456 32.4337 0 29.1468 0C25.8599 0 23.1953 2.66456 23.1953 5.95147C23.1953 9.23838 25.8599 11.903 29.1468 11.903Z" fill="currentColor"/>
    <path d="M29.1468 34.1998C32.4337 34.1998 35.0983 31.5353 35.0983 28.2484C35.0983 24.9614 32.4337 22.2969 29.1468 22.2969C25.8599 22.2969 23.1953 24.9614 23.1953 28.2484C23.1953 31.5353 25.8599 34.1998 29.1468 34.1998Z" fill="currentColor"/>
    <path d="M23.9499 17.0999C23.9499 13.8308 21.2676 11.1484 17.9985 11.1484C14.7293 11.1484 12.047 13.8308 12.047 17.0999C12.047 17.4352 12.1308 17.9382 12.2146 18.3573C12.4661 19.5308 12.7176 21.0396 11.3764 22.0455C10.3705 22.8837 9.11315 22.6323 8.10727 22.3808L7.85578 22.297C7.52049 22.2132 7.18521 22.2131 6.84992 22.2131C3.5808 22.2131 0.898438 24.8955 0.898438 28.1646C0.898438 31.4337 3.5808 34.1161 6.84992 34.1161C8.44256 34.1161 10.0352 33.4455 11.1249 32.3558C12.2985 31.1823 12.8852 29.5896 12.8014 28.0808C12.8014 27.7455 12.7176 27.3264 12.6337 26.9911C12.5499 26.7396 12.5499 26.4882 12.4661 26.2367C12.3823 25.4823 12.2984 24.5602 12.8852 23.722C13.3043 23.0514 14.0588 22.7161 14.897 22.7161C15.4838 22.7161 16.0705 22.7161 16.6573 22.8837C18.4176 23.219 20.094 22.8837 21.4352 21.8779C22.6926 20.9558 23.6146 19.447 23.7823 17.8543C23.9499 17.6029 23.9499 17.3514 23.9499 17.0999Z" fill="currentColor"/>
  </svg>

  <svg :class="{ active: showSymbol }" class="logo-text" width="201" height="27" viewBox="0 0 201 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.66628 9.33253C7.24338 9.33253 9.33255 7.24336 9.33255 4.66625C9.33255 2.08914 7.24338 0 4.66628 0C2.08917 0 0 2.08914 0 4.66625C0 7.24336 2.08917 9.33253 4.66628 9.33253Z" fill="currentColor"/>
    <path d="M22.1054 9.33253C24.6825 9.33253 26.7716 7.24336 26.7716 4.66625C26.7716 2.08914 24.6825 0 22.1054 0C19.5283 0 17.4391 2.08914 17.4391 4.66625C17.4391 7.24336 19.5283 9.33253 22.1054 9.33253Z" fill="currentColor"/>
    <path d="M22.1054 26.687C24.6825 26.687 26.7716 24.5979 26.7716 22.0208C26.7716 19.4437 24.6825 17.3545 22.1054 17.3545C19.5283 17.3545 17.4391 19.4437 17.4391 22.0208C17.4391 24.5979 19.5283 26.687 22.1054 26.687Z" fill="currentColor"/>
    <path d="M18.0101 13.3445C18.0101 10.8067 15.8817 8.67822 13.3439 8.67822C10.8061 8.67822 8.6776 10.8067 8.6776 13.3445C8.6776 13.672 8.75947 13.9994 8.84133 14.3269C9.00506 15.2274 9.25067 16.4554 8.18643 17.1921C7.36779 17.847 6.38544 17.6014 5.64866 17.4377L5.40302 17.3558C5.15743 17.274 4.91187 17.274 4.66628 17.274C2.12848 17.274 0 19.4025 0 21.9402C0 24.478 2.12848 26.6065 4.66628 26.6065C5.89424 26.6065 7.12218 26.1153 8.02269 25.2148C8.9232 24.3143 9.41441 23.0863 9.33255 21.8584C9.33255 21.6128 9.25068 21.2853 9.16881 20.9579C9.16881 20.7941 9.08692 20.5486 9.08692 20.3848C9.00505 19.8118 9.00507 19.075 9.41439 18.4201C9.74185 17.9289 10.3149 17.6015 10.9698 17.6015C11.461 17.6015 11.8703 17.6014 12.3615 17.7652C13.7532 18.0108 15.063 17.7652 16.1273 17.0284C17.1096 16.2916 17.8464 15.1455 18.0101 13.9176C18.0101 13.7538 18.0101 13.5082 18.0101 13.3445Z" fill="currentColor"/>
    <path d="M87.6794 26.6877H67.8683V0H87.6794V3.43829H71.3884V13.3439H87.6794V16.7822H71.3884V23.4132H87.6794V26.6877Z" fill="currentColor"/>
    <path d="M119.769 0H123.617L132.867 10.6424L141.954 0H145.802V26.6877H142.282V5.48489L132.867 15.9635L123.289 5.48489V26.6877H119.769V0V0Z" fill="currentColor"/>
    <path d="M155.958 19.0744L152.766 26.6877H148.918L160.215 0H164.308L175.606 26.6877H171.758L168.565 19.0744L167.174 15.7998L162.262 4.01135L157.35 15.7998L155.958 19.0744Z" fill="currentColor"/>
    <path d="M182.238 26.6877H178.718V0H190.752C193.535 0 195.828 0.818638 197.547 2.37406C199.184 3.92948 200.003 6.05794 200.003 8.6776C200.003 11.3791 199.184 13.4257 197.465 15.063C195.828 16.6184 193.535 17.4371 190.67 17.4371H182.156V26.6877H182.238ZM190.506 14.0806C192.471 14.0806 193.945 13.5895 195.009 12.6071C195.991 11.7066 196.483 10.3967 196.483 8.6776C196.483 7.04031 195.991 5.73049 195.009 4.74812C194.027 3.84761 192.471 3.35642 190.506 3.35642H182.156V14.0806H190.506Z" fill="currentColor"/>
    <path d="M95.4553 26.6877H91.9352V0H104.706C107.408 0 109.618 0.736769 111.255 2.29219C112.892 3.76575 113.711 5.89423 113.711 8.51388C113.711 11.8703 112.319 14.1625 109.536 15.3905C108.799 15.7179 108.144 15.9635 107.571 16.0454L113.957 26.6877H109.782L105.197 18.6651L102.086 13.262H104.133C106.835 13.262 108.636 12.4434 109.618 10.8061C110.027 10.233 110.191 9.41439 110.191 8.43201C110.191 6.79473 109.618 5.56676 108.554 4.66625C107.489 3.76574 106.016 3.27457 104.215 3.27457H95.2916V26.6877H95.4553Z" fill="currentColor"/>
    <path d="M59.7608 3.52016C58.6965 2.45592 57.3867 1.5554 55.8313 0.900487C54.3577 0.245573 52.8023 0 51.2468 0H39.9496V9.25066H43.4697V3.35642H51.165C53.8665 3.35642 55.995 4.33879 57.6323 6.30354C59.2696 8.18642 60.0063 10.5605 60.0063 13.3439C60.0063 16.1273 59.1877 18.4195 57.6323 20.3842C55.9132 22.349 53.7847 23.3313 51.165 23.3313H43.4697V20.7117H39.9496V26.6877H51.2468C52.8841 26.6877 54.4396 26.3603 55.8313 25.7873C57.3048 25.1323 58.6147 24.3137 59.7608 23.0857C62.2985 20.5479 63.5265 17.2734 63.5265 13.262C63.5265 9.33252 62.2985 6.05795 59.7608 3.52016Z" fill="currentColor"/>
    <path d="M43.4685 13.1805V10.7246H39.9483V13.1805H37.4105V16.7826H39.9483V19.2385H43.4685V16.7826H46.0063V13.1805H43.4685Z" fill="#FF9A24"/>
  </svg>
</template>

<script>
export default {
  props: ["showSymbol"],
}
</script>

<style lang="scss" scoped>
.logo-symbol {
  position: absolute;
  top: 20px;
  left: 18px;
  opacity: 0;
  transition: opacity .3s;
  color: var(--font-color-primary);
  &.active {
    opacity: 1;
  }
}
.logo-text {
  opacity: 0;
  transition: opacity .3s;
  color: var(--font-color-primary);
  &.active {
    opacity: 1;
  }
}
</style>
