<template>
  <div class="empty">
    <slot>
      <BaseIcon iconName="nodata"/>
      {{ t("common.NoDataFound") }}
    </slot>
  </div>
</template>

<script>

export default {
  components: {
  },
  setup() {
  },
};
</script>

<style lang="scss" scoped>
.empty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-disabled);

  svg {
    margin-bottom: 10px;
  }
}
</style>