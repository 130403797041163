

export const config = {
  DEBUG_MODE: false,
  TEST_MODE: process.env.VUE_APP_MODE == "TEST" ? true : false,
  SCREEN_MODE: (screen.width > 2560? 4 : (screen.width > 1920? 2 : 1)),
  TBP_DETECTION_RETRY_TIME: 10 * 1000,
  TBP_DETECTION_RETRY_LIMIT_MIN_TIME: 10,
  TBP_DETECTION_RETRY_COUNT: 5,
  MATCHING_SIMILARITY: 0.95,
  PATIENT_PAGE_SIZE: 15,
  PATIENT_LOG_PAGE_SIZE: 5,
  HOME_SPOT_TBP_PAGE_SIZE:  (screen.height > 1700 ? 20 : (screen.height > 1080 ? 15 : 10)),
  PATHOLOGY_PAGE_SIZE: 10,
  MAX_SIZE: 10 * 1024 * 1024,
  TBP_MSG_EFFECT_TIME: 500,
  SPOT_MARKER_WIDTH: 98,
  SPOT_MARKER_HEIGHT: 98,
  TBP_LESION_ZOOM_POPUP_WIDTH: 155,
  TBP_LESION_ZOOM_POPUP_HEIGHT: 258,
  TBP_LESION_ZOOM_POPUP_HEIGHT_LONG: 295,
  TBP_LESION_MATCHING_ZOOM_POPUP_CIRCLE: 20,

  TBP_LESION_MATCHING_LEFT_SIDE: 425,
  TBP_LESION_MATCHING_LEFT_SIDE_FULLSCREEN: 351,
  TBP_LESION_MATCHING_TOP_SIDE: 130,
  TBP_LESION_MATCHING_OFFSET_GAP: 0,

  TBP_LESION_SIZE: 10,
  TBP_LESION_CAPTURE_SIZE: 80,
  TBP_LESION_LINE_WIDTH: (screen.width > 2560 ? 1.4 : (screen.width > 1920 ? 1.2 : 1)),
  LESION_RADIUS: (screen.width > 2560 ? 10 : (screen.width > 1920 ? 7.5 : 5)),
  TBP_LESION_COLOR_NEW: "#FF1F00",
  TBP_LESION_COLOR_CHANGED: "#FAFF00",
  TBP_LESION_COLOR_NOCHANGED: "#FFFFFF",
  TBP_LESION_COLOR_DISAPPEARED: "#FFFFFF",
  TBP_LESION_COLOR_NEW_PAIR: "#000000",
  TBP_LESION_COLOR_MANUAL: "#000000",

  TBP_SELECT_COLOR: "rgba(255,255,255,1)",

  TBP_ADD_PAIR_TIMEOUT: 60 * 1000,

  DEFAULT_SIMILARITY: 0.45,

  TBP_ADD_SPOT_COLOR_SELECTED: "rgba(255,141,0,1)",
  TBP_ADD_SPOT_COLOR_DETECT_ADDED: "rgba(255,255,255,1)",
  FCM_SERVER_KEY:
    "AAAADMgDI6Q:APA91bFrkkJMxFp6sbkLnZ6PCo8B7m9jLKDDw3B1nUdpgCVYkqwjvmHM9joVl_ucVmJVhH4WqsJvsOuQoc8QsTVO9NsN7hw6pNTTASNz69Vod3QqZo2dfpZPjOsoJNjkbFMo1oxzt_Tj",
};

export const constant = {
  SCREEN_MODE_4K:4,
  SCREEN_MODE_2K:2,
  SCREEN_MODE_FHD:1,
  SCREEN_MODE_HD:0.5,


  REQUEST_STATUS_CREATE: "CREATE",
  REQUEST_STATUS_WAITING: "WAITING",
  REQUEST_STATUS_UPLOAD: "UPLOAD",
  REQUEST_STATUS_INPUTTED: "INPUTTED",
  REQUEST_STATUS_COMPLETE: "COMPLETE",

  HISTORY_TYPE_PATHOLOGYDX: "PATHOLOGY DX",
  HISTORY_TYPE_CLINICALDX: "CLINICAL DX",
  HISTORY_TYPE_PROCEDURE: "PROCEDURE",

  TBP_LESION_FILTER_DETECTED: "patients.Detected",
  TBP_LESION_FILTER_ADDED: "patients.Added",
  TBP_LESION_FILTER_MAPPED: "patients.Mapped",

  MODEL_3D_MODE_NORMAL: 0,
  MODEL_3D_MODE_ADD: 1,
  MODEL_3D_MODE_EDIT: 2,
  MODEL_3D_MODE_EXISTING: 3,

  MODEL_3D_SPOT: 0,
  MODEL_3D_TBP_MAPPING: 1,

  LESION_DRAW_MODE_DEFAULT: 0,
  LESION_DRAW_MODE_MOVE: 1,
  LESION_DRAW_MODE_MATCHING: 2,

  TBP_MATCHING_LESION_CODE_NEW: "generated",
  TBP_MATCHING_LESION_CODE_CHANGED: "change",
  TBP_MATCHING_LESION_CODE_NOCHANGED: "nochange",
  TBP_MATCHING_LESION_CODE_DISAPPEARED: "disappeared",
  TBP_MATCHING_LESION_CODE_MANUALLADD: "manual",
  TBP_MATCHING_LESION_CODE_MATCHED: "matched",
  TBP_MATCHING_LESION_CODE_MOUSEMOVE: "moved",
  TBP_MATCHING_LESION_CODE_OUT: "out",

  TBP_DRAW_LIMIT_L_MIN_X: "LMINX",
  TBP_DRAW_LIMIT_L_MAX_X: "LMAXX",
  TBP_DRAW_LIMIT_R_MIN_X: "RMINX",
  TBP_DRAW_LIMIT_R_MAX_X: "RMAXX",
  TBP_DRAW_LIMIT_MIN_X: "MINX",
  TBP_DRAW_LIMIT_MAX_X: "MAXX",
  TBP_DRAW_LIMIT_MIN_Y: "MINY",
  TBP_DRAW_LIMIT_MAX_Y: "MAXY",

  TBP_MATCH_NEW_PAIR_MODE_DEFAULT: 0,
  TBP_MATCH_NEW_PAIR_MODE_SELECTED: 1,
  TBP_MATCH_NEW_PAIR_MODE_END: 2,

  TBP_REMOVE_CURRENT_IN_PART: 1,
  TBP_REMOVE_CURRENT_IN_POSE: 2,
  TBP_REMOVE_CURRENT_OUT_PART_POSE: 3,

  TBP_CHANGE_TYPE: "changeType",

  LESION_TYPE_MALIGNANCY: "Malignancy",
  LESION_TYPE_BENIGN: "Benign",

  LESION_NAME_NEVUS: "nevus",
  LESION_NAME_SEBORRHEICKERATOSIS: "seborrheicKeratosis",
  LESION_NAME_ACTINICKERATOSIS: "actinicKeratosis",
  LESION_NAME_SOLARLENTIGO: "solarLentigo",
  LESION_NAME_DERMATOFIBROMA: "dermatofibroma",
  LESION_NAME_LINCHENOIDKERATOSIS: "linchenoidKeratosis",
  LESION_NAME_MELANOMA: "melanoma",
  LESION_NAME_BASALCELLCARCINOMA: "basalCellCarcinoma",
  LESION_NAME_SQUAMOUSCELLCARCINOMA: "squamousCellCarcinoma",

  TAKE_MOBILE_TYPE_SPOT: "SPOT",
  TAKE_MOBILE_TYPE_SPOTOVER: "SPOTOVER",
  TAKE_MOBILE_TYPE_SIGN: "SIGN",

  SEX_MALE: "m",
  SEX_FEMALE: "f",
  SEX_FULLNAME_MALE: "Male",
  SEX_FULLNAME_FEMALE: "Female",

  FAIL: "fail",
};

export const partCode = {
  A_SCALP: { name: "Scalp", type: "Anterior" },
  A_FACE: { name: "Face", type: "Anterior" },
  A_RHSEYE: { name: "Right Eyelid", type: "Anterior" },
  A_LHSEYE: { name: "Left Eyelid", type: "Anterior" },
  A_NOSE: { name: "Nose", type: "Anterior" },
  A_LIP: { name: "Lip", type: "Anterior" },
  A_RHSEAR: { name: "Right Ear", type: "Anterior" },
  A_LHSEAR: { name: "Left Ear", type: "Anterior" },
  P_SCALP: { name: "Scalp", type: "Posterior" },
  P_RHSEAR: { name: "Right Ear", type: "Posterior" },
  P_LHSEAR: { name: "Left Ear", type: "Posterior" },
  A_NECK: { name: "Neck", type: "Anterior" },
  A_CHEST: { name: "Chest", type: "Anterior" },
  A_ABDMN: { name: "Abdomen", type: "Anterior" },
  A_PELV: { name: "Pelvis", type: "Anterior" },
  A_GENIT: { name: "Genitalia", type: "Anterior" },
  P_NECK: { name: "Neck", type: "Posterior" },
  P_BACK: { name: "Back", type: "Posterior" },
  P_RHSBUT: { name: "Right Buttock", type: "Posterior" },
  P_LHSBUT: { name: "Left Buttock", type: "Posterior" },
  A_RHSTHG: { name: "Right Thigh", type: "Anterior" },
  A_LHSTHG: { name: "Left Thigh", type: "Anterior" },
  A_RHSLEG: { name: "Right Leg", type: "Anterior" },
  A_LHSLEG: { name: "Left Leg", type: "Anterior" },
  P_RHSTHG: { name: "Right Thigh", type: "Posterior" },
  P_LHSTHG: { name: "Left Thigh", type: "Posterior" },
  P_RHSLEG: { name: "Right Leg", type: "Posterior" },
  P_LHSLEG: { name: "Left Leg", type: "Posterior" },
  D_RHSFOOT: { name: "Right Foot", type: "Dorsal" },
  D_LHSFOOT: { name: "Left Foot", type: "Dorsal" },
  D_RHSTOE: { name: "Right Toe", type: "Dorsal" },
  D_LHSTOE: { name: "Left Toe", type: "Dorsal" },
  PL_RHSSOLE: { name: "Right Sole", type: "Plantar" },
  PL_LHSSOLE: { name: "Left Sole", type: "Plantar" },
  PL_RHSTOE: { name: "Right Toe", type: "Plantar" },
  PL_LHSTOE: { name: "Left Toe", type: "Plantar" },
  A_RHSSHLDR: { name: "Right Shoulder", type: "Anterior" },
  A_LHSSHLDR: { name: "Left Shoulder", type: "Anterior" },
  A_RHSARM: { name: "Right Arm", type: "Anterior" },
  A_LHSARM: { name: "Left Arm", type: "Anterior" },
  A_RHSFA: { name: "Right Forearm", type: "Anterior" },
  A_LHSFA: { name: "Left Forearm", type: "Anterior" },
  P_RHSSHLDR: { name: "Right Shoulder", type: "Posterior" },
  P_LHSSHLDR: { name: "Left Shoulder", type: "Posterior" },
  P_RHSARM: { name: "Right Arm", type: "Posterior" },
  P_LHSARM: { name: "Left Arm", type: "Posterior" },
  P_RHSFA: { name: "Right Forearm", type: "Posterior" },
  P_LHSFA: { name: "Left Forearm", type: "Posterior" },
  PA_RHSPALM: { name: "Right Palm", type: "Palmar" },
  PA_LHSPALM: { name: "Left Palm", type: "Palmar" },
  PA_RHSFNGR: { name: "Right Finger", type: "Palmar" },
  PA_LHSFNGR: { name: "Left Finger", type: "Palmar" },
  D_RHSHAND: { name: "Right Hand", type: "Dorsal" },
  D_LHSHAND: { name: "Left Hand", type: "Dorsal" },
  D_RHSFNGR: { name: "Right Finger", type: "Dorsal" },
  D_LHSFNGR: { name: "Left Finger", type: "Dorsal" },
};

export const actionCode = {
  ADDSPOT     : "common.ADDSPOT",
  DELSPOT     : "common.DELSPOT",
  ADDOVERVIEW : "common.ADDOVERVIEW",
  DELOVERVIEW : "common.DELOVERVIEW",
  ADDDETAIL   : "common.ADDDETAIL",
  DELDETAIL   : "common.DELDETAIL",
  PROCEDURE   : "common.PROCEDURE",
  CLINICALDX  : "common.CLINICALDX",
  PATHOLOGYDX : "common.PATHOLOGYDX",
  ADDTBP      : "common.ADDTBP",
  MAPTBP      : "common.MAPTBP",
  UNMAPTBP    : "common.UNMAPTBP",
  DELTBP      : "common.DELTBP",
}

export const logAction = {
  ADDDETAIL: "patientLog.Imaging",
  ADDOVERVIEW: "patientLog.Imaging",
  MAPTBP: "patientLog.Imaging",
  DELDETAIL: "patientLog.ImageDeletion",
  DELOVERVIEW: "patientLog.ImageDeletion",
  Imaging: "patientLog.Imaging",
  "Image Deletion": "patientLog.ImageDeletion",

  ADDSPOT: "patientLog.ADDSPOT",
  DELSPOT: "patientLog.DELSPOT",
  PROCEDURE: "patientLog.PROCEDURE",
  CLINICALDX: "patientLog.CLINICALDX",
  "CLINICAL DX": "patientLog.CLINICALDX",
  PATHOLOGYDX: "patientLog.PATHOLOGYDX",
  ADDTBP: "patientLog.ADDTBP",
  DELTBP: "patientLog.DELTBP",
  TAKEDEATILAPP: "patientLog.TAKEDEATILAPP",
  TAKEVIEWAPP: "patientLog.TAKEVIEWAPP",
  TAKETBPAPP: "patientLog.TAKETBPAPP",
  MATCHING: "patientLog.MATCHING",
  UNMAPTBP: "patientLog.UNMAPTBP"
}

export const logDetail = {
  "Documented Overview image(s)": "patientLog['Documented Overview image(s)']",
  "Documented Dermoscopic image(s)": "patientLog['Documented Dermoscopic image(s)']",
  "Deleted Overview image(s)": "patientLog['Deleted Overview image(s)']",
  "Deleted Dermoscopic image(s)": "patientLog['Deleted Dermoscopic image(s)']",
  "Mapped TBP image": "patientLog['Mapped TBP image']",
  
  PUNCH: "patientLog.PUNCH",
  SHAVE: "patientLog.SHAVE",
  CURETTAGE: "patientLog.CURETTAGE",
  CRYO: "patientLog.CRYO",
  EXCISION: "patientLog.EXCISION",
  REMOVESITURE: "patientLog.REMOVESITURE",
  TOPT: "patientLog.TOPT",
  PDT: "patientLog.PDT",
  WOUNDCHECK: "patientLog.WOUNDCHECK",
  DIATHERMY: "patientLog.DIATHERMY"
}

export const lesionType = {
  LESION_NAME_NEVUS: "nevus",
  LESION_NAME_SEBORRHEICKERATOSIS: "seborrheicKeratosis",
  LESION_NAME_ACTINICKERATOSIS: "actinicKeratosis",
  LESION_NAME_SOLARLENTIGO: "solarLentigo",
  LESION_NAME_DERMATOFIBROMA: "dermatofibroma",
  LESION_NAME_LINCHENOIDKERATOSIS: "linchenoidKeratosis",
  LESION_NAME_MELANOMA: "melanoma",
  LESION_NAME_BASALCELLCARCINOMA: "basalCellCarcinoma",
  LESION_NAME_SQUAMOUSCELLCARCINOMA: "squamousCellCarcinoma",
}

export const lesionLabel = {
  LESION_NAME_NEVUS: "patients.LesionLabel_Nevus",
  LESION_NAME_SEBORRHEICKERATOSIS: "patients.LesionLabel_SeborrheicKeratosis",
  LESION_NAME_ACTINICKERATOSIS: "patients.LesionLabel_ActinicKeratosis",
  LESION_NAME_SOLARLENTIGO: "patients.LesionLabel_SolarLentigo",
  LESION_NAME_DERMATOFIBROMA: "patients.LesionLabel_Dermatofibroma",
  LESION_NAME_LINCHENOIDKERATOSIS: "patients.LesionLabel_LichenoidKeratosis",
  LESION_NAME_MELANOMA: "patients.LesionLabel_Melanoma",
  LESION_NAME_BASALCELLCARCINOMA: "patients.LesionLabel_BasalCellCarcinoma",
  LESION_NAME_SQUAMOUSCELLCARCINOMA: "patients.LesionLabel_SquamousCellCarcinoma",
};

//변동 상수
export const getLesionLineWidth = () => {
  return (screen.width > 2560 ? 1.4 : (screen.width > 1920 ? 1.2 : 1))
}

//변동 상수
export const getLesionRadius = () => {
  return (screen.width > 2560 ? 10 : (screen.width > 1920 ? 7.5 : 5))
}