<template>
  <div class="toast-wrap">
    <transition name="fade">
      <div v-if="isShow" class="toast-box">
        <span v-show="type === 'success'" class="toast-icon success">
          <BaseIcon iconName="check" />
        </span>
        <span v-show="type === 'fail'" class="toast-icon fail">
          <BaseIcon iconName="x" />
        </span>
        {{ msg }} 
        <button @click="isToast = false;">
          <BaseIcon iconName="x" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { useToastStore } from '@/stores/store.toast';
import { storeToRefs } from "pinia";

const { isShow, type, msg } = storeToRefs(useToastStore());

</script>

<style lang="scss" scoped>
.toast-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 80px 0;
  pointer-events: none;
  z-index: 99999;
  transform: translate3d(-50%, -50%, 0);
}
.toast-icon {
  position: absolute;
  top: 20px;
  left: 18px;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 4px;
  text-align: center;
  line-height: 26px;
  color: white;
  border-radius: 50%;
  background-color: var(--box-color-border);
  &.success {
    background-color: var(--icon-color-edit);
  }
  &.fail {
    background-color: var(--icon-color-delete);
  }

  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
.toast-box {
  position: relative;
  width: 600px;
  padding: 20px 40px 20px 60px;
  font-size: 16px;
  background: var(--background-color-secondary);
  border: 1px solid var(--box-color-border);
  box-shadow: var(--box-color-shadow);
  border-radius: 6px;
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
  }
}
</style>