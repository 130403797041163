export default [
  {
    path: "/:pathMatch(.*)*",
    // redirect: "/404",
    component: () => import("@/views/NotFound"),
  },
  {
    path: "/",
    name: "home",
    meta: { layout: "default" },
    component: () => import("@/views/home/index"),
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "" },
    component: () => import("@/views/account/login"),
  },
  {
    path: "/patient",
    component: () => import("@/views/patient/index"),
    children: [
      {
        path: "",
        name: "patientList",
        meta: { layout: "default" },
        component: () => import("@/views/patient/list"),
      },
      {
        path: "view",
        name: "patientView",
        meta: { layout: "default" },
        component: () => import("@/views/patient/view"),
      },
      {
        path: "tbp",
        name: "patientTbp",
        meta: { layout: "default" },
        component: () => import("@/views/patient/tbp"),
      },
      {
        path: "spot",
        name: "patientSpot",
        meta: { layout: "default" },
        component: () => import("@/views/patient/spot"),
      },
    ],
  },
  {
    path: "/pathology",
    name: "pathology",
    meta: { layout: "default" },
    component: () => import("@/views/pathology/index"),
  },
  {
    path: "/statistics",
    name: "statistics",
    meta: { layout: "default" },
    component: () => import("@/views/statistics/index"),
  },
  {
    path: "/setting",
    component: () => import("@/views/setting/index"),
    children: [
      {
        path: "",
        name: "doctor",
        meta: { layout: "default" },
        redirect: "/setting/profile",
      },
      {
        path: "profile",
        name: "profile",
        meta: { layout: "default" },
        component: () => import("@/views/setting/profile"),
      },
      {
        path: "consent",
        name: "consent",
        meta: { layout: "default" },
        component: () => import("@/views/setting/consent"),
      },
      {
        path: "question",
        name: "question",
        meta: { layout: "default" },
        component: () => import("@/views/setting/question"),
      },
      {
        path: "device",
        name: "device",
        meta: { layout: "default" },
        component: () => import("@/views/setting/device"),
      },
      {
        path: "notice",
        name: "notice",
        meta: { layout: "default" },
        component: () => import("@/views/setting/notice"),
      },
      {
        path: "manual",
        name: "manual",
        meta: { layout: "default" },
        component: () => import("@/views/setting/manual"),
      },
    ],
  },
  {
    path: "/setting/clinic",
    component: () => import("@/views/setting/clinic/index"),
    children: [
      {
        path: "",
        name: "clinic",
        meta: { layout: "default" },
        redirect: "/setting/clinic/profile",
      },
      {
        path: "profile",
        name: "clinicProfile",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/profile"),
      },
      {
        path: "members",
        name: "clinicMembers",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/members"),
      },
      {
        path: "tbp",
        name: "clinicTbp",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/tbp"),
      },
      {
        path: "consent",
        name: "clinicConsent",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/consent"),
      },
      {
        path: "question",
        name: "clinicQuesion",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/question"),
      },
      {
        path: "device",
        name: "clinicDevice",
        meta: { layout: "default" },
        component: () => import("@/views/setting/clinic/device"),
      },
    ],
  },
  {
    path: "/activites",
    name: "activites",
    meta: { layout: "default" },
    component: () => import("@/views/activites/index"),
  },
  {
    path: "/feedback",
    name: "feedback",
    meta: { layout: "default" },
    component: () => import("@/views/feedback/index"),
  },
  {
    path: "/news",
    name: "news",
    meta: { layout: "default" },
    component: () => import("@/views/news/index"),
  },
  {
    path: "/icons",
    name: "icons",
    meta: { layout: "blank" },
    component: () => import("@/views/icons/index"),
  },
];
