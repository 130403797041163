import { getToken } from 'firebase/messaging';
import { setFireBase } from '@/plugin/fcm/firebase';
import { onMessage } from "firebase/messaging";
import { isIP } from '@/common/util';

import { useAuthStore } from "@/stores/store.auth";
import { useMainStore, useDoctorStore } from "@/stores";
import DoctorApi from "@/api/api.doctor";
import ClinicApi from "@/api/api.clinic";

let installed = false;

let fcmToken;
let fcmTopic;

const installFcm = async () => {
    const { clinicSeq } = useDoctorStore();
    const { getDoctorSeq } = useAuthStore();
    
    console.log("install FCM", installed);
    if(installed){
        return;
    }
    const hostname = window.location.hostname;
    if(isIP(hostname)){
        return;
    }

    const messaging = await setFireBase();
    const code = process.env.VUE_APP_REGION;

    await getFcmToken(messaging, async(token)=>{
        try{
            // console.log("fcm token", token);

            if(token){
                fcmToken = token;
                fcmTopic = code+"clinic"+clinicSeq;
                //토큰 등록
                subscribeTokenToTopic();
            
                const param = {
                    doctorSeq : getDoctorSeq(),
                    pushKey : token
                }
                await DoctorApi.updateDoctorPushKey(param);
            }
        } catch(err) {
            console.error("subscribe Token Topic errror",err);
        }
    });

    onMessage(messaging, (payload) => {
        const { setFcmMessage } = useMainStore();
        // console.log('Message received. ');
        console.log('Message received. ', payload);

        if(payload){
            const data = {
                messageId: payload.messageId,
                title: payload.notification.title,
                body: payload.notification.body
            }
    
            setFcmMessage(data);
        }
    })
}

const getFcmToken = (messaging, callback) => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            //getToken(messaging);
            getToken(messaging).then((currentToken) => {
                if (currentToken) {
                    // console.log('Current token:', currentToken);
                    callback(currentToken);
                } else {
                    console.error('No registration token available.');
                }
            })
            .catch((err) => {
                console.error('An error occurred while retrieving token:', err);
            });
        }
    })
}

const subscribeTokenToTopic = async () => {    
    try {
        let param = JSON.stringify({ token: fcmToken, topic: fcmTopic });

        await ClinicApi.subscribeWebPush(param);
        installed = true;

        console.log("successed subscribe");
    } catch (error) {
        console.error("Failed subscribe")
    }
}

const fcmLogout = async () => {
    console.log("fcmLogout");
    try {
        let param = JSON.stringify({ token: fcmToken, topic: fcmTopic });
        await ClinicApi.unsubscribeWebPush(param);
        installed = false;
    } catch (error) {
        console.error("Failed subscribe")
    }
}


export {
    getFcmToken, 
    subscribeTokenToTopic, 
    installFcm,
    fcmLogout
}
