import { stringify as qsStringify } from 'qs';
import http from "./http"
import {AuthModel, AuthEmailCheckModel} from "@/types/type.auth"

class AuthApi{
	/** login */
	async login(value:Object):Promise<AuthModel>{
		try{
			const response = await http.post(`/auth/doctorLogin`, value);
			return response.data;
		}catch (error){
            return Promise.reject(error);
		}
	}

	/** login */
	async refreshToken(value:Object):Promise<AuthModel>{
		try{
			const response = await http.post(`/auth/reissueAccessToken`,value);
			return response.data;
		}catch (error){
            return Promise.reject(error);
		}
	}
	
	/** login */
	async requestEmailNumberCheck(email:string, language: 'en' | 'de'):Promise<AuthEmailCheckModel>{
		try{
			const response = await http.get(`/auth/requestEmailNumberCheck?${qsStringify({ email, language })}`);
			return response.data;
		}catch (error){
            return Promise.reject(error);
		}
	}

	/** login */
	async updateDoctorPassword(value:Object):Promise<Object>{
		try{
			const response = await http.post(`/auth/updateDoctorPassword`,value);
			return response.data;
		}catch (error){
            return Promise.reject(error);
		}
	}

	
}

export default new AuthApi();