import { createRouter, createWebHistory } from "vue-router";

import routes from "./routes";

import { useClinicStore, useDoctorStore } from "@/stores";
import { installFcm } from "@/plugin/fcm/firebasePlugin";

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "instant" };
  },
});

//네비게이션 가드(뷰 라우터로 URL 접근에 대해서 처리할 수 있음)
router.beforeEach(async (to, from, next) => {
  if(to.name !== "login"){
    installFcm();
  }
  if(!(["login","patientTbp","patientSpot"].some((name)=>name===to.name))){
    try{
      const { getClinic } = useClinicStore();
      const { getDoctor, doctorSeq, clinicSeq } = useDoctorStore();
      await getDoctor(doctorSeq, true);
      await getClinic(clinicSeq, true);
      next();
    }catch(err){
      next('/login');
    }
    
  }else{
    next();
  }
  
});

export default router;
