import http from "./http";
import { stringify as qsStringify } from 'qs';
import { PatientModel, SpotFollowUpModel, PatientLogListModel, PatientConsentSign, PdfPatient } from '@/types/type.patient';

class PatientApi {

  /** 환자 추가 */
  async addPatient(value:PatientModel):Promise<PatientModel> {
    try {
      const response = await http.post(`/patient/addPatient`, value);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 리스트 가져오기 */
  async getPatients(clinicSeq:number):Promise<PatientModel[]> {
    try {
      const response = await http.get(`/patient/getPatientListByClinicSeq?clinicSeq=${clinicSeq}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 리스트 가져오기 */
  async getPatient(patientSeq:number):Promise<PatientModel> {
    try {
      const response = await http.get(`/patient/getPatient?patientSeq=${patientSeq}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 로그 가져오기 */
  async getPatientLogs(index:number, size:number,patientSeq:number):Promise<PatientLogListModel>{
    try {
      const response = await http.get(`/patient/getPatientLogPageListByPatientSeq?index=${index}&size=${size}&patientSeq=${patientSeq}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 로그 가져오기 */
  async getFollowupList(patientSeq:number):Promise<SpotFollowUpModel[]>{
    try {
      const response = await http.get(`/spot/getFollowupListByPatientSeq?patientSeq=${patientSeq}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 지우기 */
  async deletePatient(value:PatientModel):Promise<PatientModel> {
    try {
      const response = await http.post(`/patient/addPatient`, value);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 환자 서명동의서 리스트 조회 */
  async getConsentSignList(patientSeq:number):Promise<PatientConsentSign[]>{
    try {
      const response = await http.get(`/patient/getConsentSignListByPatientSeq?patientSeq=${patientSeq}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPdfPatientByPatientSeq(patientSeq: number, startDate: string, endDate: string): Promise<PdfPatient> {
    try {
      const response = await http.get(`/patient/getPdfPatientByPatientSeq?${
        qsStringify({ patientSeq, startDate, endDate })}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // /** 스팟 요약 정보 가져오기 */
  // async getSpotSummary(spotId:number):Promise<SpotSummary[]> {
  //   try {
  //     const response = await http.get(`/spot/getSpotSummary?spotSeq=${spotId}`);
  //     return response.data;
  //   } catch (error) {
  //     throw new Error("getSpotSummary()");
  //   }
  // }

  // /** TBP 요약 정보 가져오기 */
  // async getTbpSummary(tbpId:number):Promise<TBPSummary[]> {
  //   try {
  //     const response = await http.get(`/spot/getTbpSummary?tbpSeq=${tbpId}`);
  //     return response.data;
  //   } catch (error) {
  //     throw new Error("getTbpSummary()");
  //   }
  // }
}

export default new PatientApi();