import { defineStore } from 'pinia';

const MSG_SHOW_TIME = 3; // sec

export const useToastStore = defineStore('toast', {
  state: () => ({
    isShow: false,
    type: "type",
    msg: "message"
  }),
  actions: {
    showToast({type="", msg=""} = {}) {
      this.type = type;
      this.msg = msg;
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, MSG_SHOW_TIME * 1000);
    },
    showErrorToast(msg:string) {
      this.type = "fail";
      this.msg = msg;
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, MSG_SHOW_TIME * 1000);
    },
  }
});