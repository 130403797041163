import http from "./http";
import {
  ClinicModel,
  MenualModal,
  NoticeModal,
  TemplateModel,
  WebPushRequest,
} from "@/types/type.clinic";
import { FeedbackModel } from "@/types/type.feedback";

class ClinicApi {

  /** 병원 정보 가져오기 */
  async getClinic(clinicId: number): Promise<ClinicModel> {
    try {
      const response = await http.get(
        `/clinic/getClinic?clinicSeq=${clinicId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 병원 정보 업데이트 */
  async updateClinic(value: ClinicModel): Promise<ClinicModel> {
    try {
      const response = await http.post(`/clinic/updateClinic`, value);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** TBP 정보 가져오기 */
  async getTbpGroupSetListByClinicSeq(
    clinicId: number
  ): Promise<TemplateModel[]> {
    try {
      const response = await http.get(
        `/clinic/getTbpGroupSetListByClinicSeq?clinicSeq=${clinicId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** TBP 정보 업데이트 */
  async updateTbpPartGroupSet(value: TemplateModel): Promise<TemplateModel> {
    try {
      const response = await http.post(`/clinic/updateTbpPartGroupSet`, value);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addFeedback(payload: FeedbackModel): Promise<FeedbackModel> {
    try {
      const response = await http.post(`/clinic/addFeedback`, payload);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getNoticeList(): Promise<NoticeModal[]> {
    try {
      const response = await http.get(`/clinic/getWebNoticeList`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getManualList(): Promise<MenualModal[]> {
    try {
      const response = await http.get(`/clinic/getWebManualList`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendWebPush(payload: WebPushRequest): Promise<void> {
    try {
      const response = await http.post(`/clinic/sendWebPush`, payload);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async subscribeWebPush(param: Map<string, String>): Promise<void> {
    try {
      const response = await http.post(`/clinic/subscribeWebPush`, param);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async unsubscribeWebPush(param: Map<string, String>): Promise<void> {
    try {
      const response = await http.post(`/clinic/unsubscribeWebPush`, param);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new ClinicApi();
