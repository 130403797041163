<template>
  <header id="page-topbar">
    <div class="user-avatar" :class="{inner: isSpotTBP}" v-click-outside="clickOutside">
      <button class="button-avatar" @click="clickAvatar">
        <base-avatar class="small" :picture="doctor?.profileImgUrl"/>
      </button>
      <div class="prfile_viewer" :class="{ active: isPanel }">
        <div class="buttons">
          <button class="out" @click="logOut">
            <BaseIcon iconName="logout"/>
          </button>
          <!-- 24.04.25 요청사항 : 테마변경 버튼 disabled 처리 (수정자 : 배진웅) -->
          <!-- <button class="theme" @click="changeTheme">
            <BaseIcon iconName="theme"/>
          </button> -->
        </div>
        <div class="inner">
          <router-link to="/setting/profile">
            <base-avatar 
              class="large" 
              :picture="doctor?.profileImgUrl" 
              :editable="false"
              @click="clickAvatar"
            />
          </router-link>
          <p>{{ doctorName }}</p>
          <span>{{ doctor?.email }}</span>
        </div>

        <!-- <button class="feed" @click="clickFeedback">
          {{ t("common.Feedback") }}
        </button> -->
        <button class="feed" @click="clickHelpCenter">
          {{ t("common.HelpCenter") }}
        </button>
      </div>
    </div>

    <!-- modal: delete patient -->
    <BaseModal :show="isFeedback">
      <DoctorFeedback
          @cancel="isFeedback = false"
      />
    </BaseModal>

  </header>
</template>

<script setup>

//vue import
import { onMounted, watch, ref, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

//components import
import DoctorFeedback from "@/components/home/modal/DoctorFeedback";

//store import
import { storeToRefs } from "pinia";
import { useDoctorStore } from "@/stores/store.doctor";
import { useMainStore } from "@/stores";

//store
const {getDoctor, doctorSeq} = useDoctorStore();
const {doctor} = storeToRefs(useDoctorStore());
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {changeTheme, resetForLogout} = useMainStore();
const route = useRoute();

//ref
const isPanel = ref(false);
const isSpotTBP = ref(false);
const isFeedback = ref(false);
// const profile = ref(null);

const clickAvatar = () => {
  getDoctor(doctorSeq);
  isPanel.value = !isPanel.value;
};

const doctorName = computed(() => `${doctor.value?.firstName ?? " "}  ${doctor.value?.lastName ?? " "}`);

const clickOutside = () => {
  isPanel.value = false;
};

// const clickFeedback = () => {
//   isFeedback.value = true;
//   isPanel.value = false;
// };
const clickHelpCenter = () => {
  isPanel.value = false;
  window.open("https://dermap-team.atlassian.net/servicedesk/customer/portal/1", "_blank");
};

const resetPosition = (name) => {
  const routes = [ "patientSpot", "patientTbp" ];
  isSpotTBP.value = routes.includes(name);
};

onMounted(() => {
  resetPosition(route.name);
});

watch(() => route.name,
    async name => {
      resetPosition(name);
    },
);

const logOut = () => {
  resetForLogout();
  router.push("/login");
};
</script>

<style lang="scss" scoped>
#page-topbar {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 3;
}

.button-avatar {
  line-height: 0;
}

.user-avatar {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 0;

  &.inner {
    top: 40px;
    right: 40px;
  }

  .small {
    width: 40px;
    height: 40px;
  }

  .large {
    width: 70px;
    height: 70px;
    margin-top: 20px;
  }
}

.prfile_viewer {
  position: absolute;
  top: 45px;
  right: -2px;
  width: 320px;
  border-radius: 15px;
  border: 1px solid var(--box-color-border);
  background: var(--box-color-background);
  text-align: center;
  overflow: hidden;
  display: none;
  z-index: 10;

  &.active {
    display: block;
  }

  .inner {
    position: relative;
    padding: 20px;
  }

  p {
    margin-top: 8px;
    font-size: 16px;
    color: var(--font-color-primary);
  }

  span {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    color: var(--font-color-secondary);
  }

  .feed {
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--color-primary);
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 1;

    button {
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: var(--font-color-secondary);

      &:hover {
        background-color: var(--color-hover);
      }
    }
  }

  .out {
    padding-left: 2px;
  }

  /*.out {
    color: var(--font-color-secondary);
  }
  .theme {
    position: absolute;
    top: 58px;
    right: 26px;
    color: var(--font-color-secondary);
    z-index: 1;
    &:hover {
      background-color: var(--color-hover);
    }
  }*/
}
</style>