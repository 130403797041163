import { defineStore } from "pinia";
import {
  ClinicStoreModel,
  ClinicModel,
  TemplateModel,
} from "@/types/type.clinic";
import ClinicApi from "@/api/api.clinic";
import { initClinicData } from "@/controller/DataController";

export const useClinicStore = defineStore("clinic", {
  state: (): ClinicStoreModel => ({
    clinic: initClinicData(),
    tbpGroupSets: [],
  }),
  actions: {
    /** clinic 정보 가져오기 */
    async getClinic(
      clinicSeq: number,
      isHasCheck: boolean = false
    ): Promise<ClinicModel> {
      if (isHasCheck) {
        if (this.clinic && this.clinic.clinicName) {
          return this.clinic;
        }
      }
      const data = await ClinicApi.getClinic(clinicSeq);
      this.clinic = data;
      localStorage.setItem("tbpRegType", String(this.clinic.tbpRegType));
      return data;
    },

    /** clinic 정보 업데이트 */
    async updateClinic(value: ClinicModel): Promise<ClinicModel> {
      const data = await ClinicApi.updateClinic(value);
      return data;
    },

    /** clinic tbp 그룹 리스트 정보 가져오기 */
    async getTbpGroupSetListByClinicSeq(
      clinicSeq: number
    ): Promise<TemplateModel[]> {
      const data = await ClinicApi.getTbpGroupSetListByClinicSeq(clinicSeq);
      this.tbpGroupSets = data;
      return data;
    },

    /** clinic tbp 그룹 리스트 업데이트 */
    async updateTbpPartGroupSet(value: TemplateModel): Promise<TemplateModel> {
      const data = await ClinicApi.updateTbpPartGroupSet(value);
      if (data?.tbpPartGroups) {
        localStorage.setItem(
          "tbpPartGroupList",
          JSON.stringify(data.tbpPartGroups)
        );
      }
      return data;
    },
  },
});
