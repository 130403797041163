import { capitalizeFirstLetter, getAge, genderFullWord } from '@/common/util';
import { Waiter } from './CommonController';

export const setPatientFormat = (result) => {
    return {
        ...result,
        firstName : capitalizeFirstLetter(result.firstName),
        lastName : capitalizeFirstLetter(result.lastName),
        age: result.birth ? getAge(result.birth) : "",
        gender: genderFullWord(result.gender),
    }
}

export const reportImagesLoadWaiter = new Waiter();