<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="show" class="modal-mask" :class="{low:isLowBlur}">
        <div class="modal-wrapper">
          <div class="modal-container">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { onUnmounted, ref, watch } from 'vue';
export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isLowBlur:Boolean
  },
  setup(props, context) {
    const showModal = ref(false);

    watch(() => props.show, (show) => {
      show ? display() : hidden();
    })

    onUnmounted(() => {
      hidden();
    })

    const clickOutside = () => {
      context.emit("close");
    };
    
    const display = () => {
      const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
      document.getElementsByTagName('html')[0].style.paddingRight = scrollbarWidth + "px";
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
    }

    const hidden = () => {
      document.getElementsByTagName('html')[0].style.paddingRight = "";
      document.getElementsByTagName('body')[0].style.overflow = "";
    }

    return {
      showModal,
      clickOutside
    }
  }
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(73, 73, 73, 0.8);
  backdrop-filter: blur(10px);
  overflow: auto;

  &.low{
    background: rgba(73, 73, 73, 0.3);

  }
  .modal-body {
    position: relative;
    padding: 40px;
    background-color: var(--background-color-primary);
    border: 1px solid var(--box-color-border);
    border-bottom: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    &.round {
      border-radius: 15px;
    }
  }
  .modal-title {
    margin-bottom: 45px;
  }
}

.modal-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.modal-container {
  position: relative;
  flex: none;
  margin: 0 auto;
  text-align: left;
}

.modal-alert {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 170px;
  text-align: center;
  p {
    margin: 10px 0;
    font-weight: 700;
    font-size: 18px;
    color: var(--font-color-primary);
  }
  span {
    font-weight: 400;
    font-size: 16px;
    color: var(--font-color-secondary);
  }
}

.modal-foot-buttons {
  display: flex;
  border: 1px solid var(--box-color-border);
  border-top: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  button {
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: var(--font-color-primary);
    background-color: var(--box-color-background);
    &:disabled {
      color: var(--font-color-secondary);
      background: var(--color-disabled);
    }
  }
  .confirm {
    color: white;
    background-color: var(--color-primary);
  }
}

.modal-h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--font-color-primary);
}

.modal-h4 {
  font-size: 14px;
  color: var(--font-color-primary);
}

.modal-para {
  font-size: 16px;
  font-weight: 400;
  color: var(--font-color-secondary);
}

.modal-enter,
.modal-enter-from {
// .modal-leave-to {
  opacity: 0;
}

.modal-enter-active {
// .modal-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from {
  opacity: 0;
}
</style>