import { useQuestionnaire } from "@/stores/store.question";
import { useDoctorStore } from "@/stores/store.doctor";
import { useClinicStore } from "@/stores/store.clinic";
import { usePatientStore } from "@/stores/store.patient";
import { useToastStore } from "@/stores/store.toast"
import { defineStore } from "pinia";
import { FcmMessage, MainStoreModel } from "@/types/type.main";
import { fcmLogout } from "@/plugin/fcm/firebasePlugin";

export const useMainStore = defineStore("main", {
  state: () : MainStoreModel => ({ 
    fcmMessage : null
  }),
  actions:{
    ////////////  테마 관련
    applyTheme(value:string) {
      const doc = document.getElementsByTagName("html")[0];
      doc.setAttribute("data-theme", value);
      localStorage.setItem("theme", value);
    },
    changeTheme() {
      switch(localStorage.getItem("theme")) {
        case "dark":
          this.applyTheme("light");
          break;
        default:
          this.applyTheme("dark");
          break;
      }
    },

    ////////////  FCM 관련
    setFcmMessage(data: FcmMessage) {
      this.fcmMessage = data;
    },

    async resetForLogout() {
      await fcmLogout();      
      const questionStore = useQuestionnaire();
      const doctorStore = useDoctorStore();
      const clinicStore = useClinicStore();

      // 필요한 모든 스토어의 reset 메서드를 호출합니다 ...
      if(questionStore)questionStore.$reset();
      if(doctorStore)doctorStore.$reset();
      if(clinicStore)clinicStore.$reset();

      localStorage.removeItem('userAuth');
      localStorage.removeItem('userData');
      localStorage.removeItem('defaultTbpPartList');
      localStorage.removeItem('doctorSeq');
      localStorage.removeItem('clinicSeq');
      localStorage.removeItem('profileImgUrl');
      localStorage.removeItem('tbpRegType');
      localStorage.removeItem('patientSeq');
    }
  }
});

export {
  useDoctorStore,
  usePatientStore,
  useClinicStore,
  useToastStore,
}
