import http from "./http";
import {QuestionModel, QuestionFormModel, QuestionAnswerModel} from '@/types/type.question';
import { DoctorModel } from "@/types/type.doctor";

class QuestionnaireApi{
    async addQuestionForm(value:QuestionModel):Promise<QuestionModel>{
        try{
            const response = await http.post(`/clinic/addQuestionForm`, value);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }
    }

    async getQuestionFormFromDoctor(doctorSeq:number):Promise<QuestionFormModel[]>{
        try{
            const response = await http.get(`/patient/getQuestionFormListByDoctorSeq?doctorSeq=${doctorSeq}`);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }

    async getQuestionFormList(clinicSeq:number, doctorSeq:number):Promise<QuestionFormModel[]>{
        try{
            let url = `/clinic/getQuestionFormListByClinicSeq?clinicSeq=${clinicSeq}`;
            if(doctorSeq) url += `&doctorSeq=${doctorSeq}`;
            
            const response = await http.get(url);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }

    async setQuestionFormDefault(value:DoctorModel):Promise<QuestionModel>{
        try{
            const response = await http.post(`/doctor/updateDoctor`, value);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }   

    async setQuestionFormDefaultForClinic(value:QuestionModel):Promise<QuestionModel>{
        try{
            const response = await http.post(`/clinic/updateQuestionFormDefault`, value);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }    

    async addQuestionAnswers(value:QuestionAnswerModel):Promise<QuestionModel>{
        try{
            const response = await http.post(`/patient/addQuestionAnswers`, value);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }    

    async getQuestionForm(questionFormSeq:number):Promise<QuestionFormModel>{
        try{
            const response = await http.get(`/patient/getQuestionForm?questionFormSeq=${questionFormSeq}`);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }

    async getQuestionAnswer(patientSeq:number, questionFormSeq:number):Promise<QuestionFormModel[]>{
        try{
            const response = await http.get(`/patient/getQuestionSetByQuestionFormSeqAndPatientSeq?patientSeq=${patientSeq}&questionFormSeq=${questionFormSeq}`);
            return response.data;
        }catch(error){
            return Promise.reject(error);
        }    
    }   
}

export default new QuestionnaireApi();