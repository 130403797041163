<template>
  <div class="loading">
    <slot>
      <div class="loading-ellipsis"><div></div><div></div><div></div><div></div></div>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--font-color-primary);
  z-index: 9999;
  animation: fadein 1s;
  &.bgColor {
    background-color: rgba(0,0,0,0.4);
  }
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
}
@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
.loading-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 30px;
}
.loading-ellipsis div {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loading-ellipsis1 0.6s infinite;
}
.loading-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loading-ellipsis3 0.6s infinite;
}
@keyframes loading-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>