<template>
  <div class="slider" :disabled="disabled">
    <button 
      @click="minus" 
      :disabled="disabled" 
      class="slider-minus"
    >-</button>

    <input 
      type="range" 
      :value="modelValue"
      :disabled="disabled"
      :min="min" 
      :max="max" 
      :step="step" 
      @input="changeValue($event.target.value)"
    >

    <button 
      @click="plus" 
      :disabled="disabled" 
      class="slider-plus"
    >+</button>

    <div class="slider-value">{{ Math.round(modelValue) }}%</div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  step: {
    type: Number,
    default: 1
  },
  disabled: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits([
  'update:modelValue',
  // 'change'
]);

const plus = () => {
  const { modelValue, max, step } = props;
  let cValue = modelValue + step;
  cValue = cValue >= max ? max : cValue;
  changeValue(cValue);
}

const minus = () => {
  const { modelValue, min, step } = props;
  let cValue = modelValue - step;
  cValue = cValue <= min ? min : cValue;
  changeValue(cValue);
}

const changeValue = (value) => {
  emit('update:modelValue', Number(value));
}
</script>

<style lang="scss" scoped>
.slider {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 180px;
  height: 20px;
  background: var(--box-color-border);
  border-radius: 20px;
  &:not(:disabled):hover {
    .slider-value {
      display: block;
    }
  }
  input {
    width: calc(100% - 48px);
    vertical-align: middle;
    border-radius: 3px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    width: 24px;
    height: 20px;
    font-size: 18px;
    line-height: 0;
  }
}
.slider-plus {
  top: 4px;
}
.slider-minus {
  bottom: 4px;
}
.slider-value {
  position: absolute;
  bottom: 24px;
  left: 50%;
  display: none;
  padding: 0 4px;
  font-size: 12px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  transform: translate(-50%, 0);
}
</style>