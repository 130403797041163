import ko from '@/locale/ko.json'
import en from '@/locale/en.json'
import de from '@/locale/de.json'
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
// import { createI18n } from 'vue-i18n'

const messages = {
    'ko' : ko,
    'en' : en,
    'de' : de
}

const localStorageLocale = localStorage.getItem("userLocale");

export const i18n = createI18n({
    legacy: false, 
    locale: localStorageLocale 
        || window.navigator.language.substring(0, 2) //국가코드를 제외한 언어코드만 가져오기
        || 'en',
    messages: messages
});

export function changeI18nLanguage(language) {
    i18n.global.locale.value = language;
}