<template>
  <component :is="layout" >
    <slot  />
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';

export default {
  components: {
    default: DefaultLayout,
    blank: BlankLayout,
  },
  setup() {
    const route = useRoute()
    const layout = computed(() => route.meta.layout || 'blank' );
    return {
      layout,
    }
  }
}
</script>