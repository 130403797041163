import { defineStore } from 'pinia';
import { QuestionFormModel, QuestionModel, QuestionStoreModel, QuestionAnswerModel } from '@/types/type.question';
import { DoctorModel } from '@/types/type.doctor';
import QuestionnaireApi from '@/api/api.question';

export const useQuestionnaire = defineStore('questionnaire',{
    state: () :QuestionStoreModel => ({
        questionForms: null,
        questionForm:null,
        loading:false,
        error:null
    }),

    actions: {
        /**
         * 현재 선택되어 있는 QuestionForm
         * @param questionForm  : QuestionForm
         * @param adminFlag     : 관리자 여부
         */
        activeQuestionForm(questionForm:QuestionFormModel, adminFlag:boolean){
            if(questionForm){
                if(adminFlag){
                    questionForm.adminFlag = true;
                }
                questionForm.questions.forEach(function (question) {
                    try{
                        question.questionOptionsArray = JSON.parse(question.questionOptions);
                    }catch(error){
                        console.error("JSON ParseError",error);
                    }
                });
                this.questionForm = questionForm;

            }else{
                this.newQuestionForm(adminFlag);
            }
            
        },

        newQuestionForm(adminFlag:boolean){
            this.questionForm = {
                formName: "",
                clinicSeq: 0,   
                lastName: "",   
                firstName: "",  
                adminFlag: adminFlag,
                questions:[
                    {
                    bodyText:"",
                    questionOrder: 1,
                    type:"single",
                    questionOptions: "",
                    questionOptionsArray: [{
                        optionSeq: 1,
                        data: "",
                    }]
                    }
                ] 
            }

            return this.questionForm;
        },

        findData(data:Array<QuestionFormModel>, key:string):Boolean{
            data.forEach((qf) => {
                if( (key == "useFlag" && qf[key]) || (key == "defaultFlag" && qf[key])){
                    qf.mainFlag = true;
                    return true;
                }
            });

            return false;
        },

        async addQuestionForm(value:QuestionModel):Promise<QuestionModel>{
            const data = await QuestionnaireApi.addQuestionForm(value);
            return data;
        },

        
        async getQuestionFormFromDoctor(doctorSeq:number):Promise<Array<QuestionFormModel>>{
            const data = await QuestionnaireApi.getQuestionFormFromDoctor(doctorSeq);
            this.questionForms = [];
            this.questionForms = data;

            const defaultArr = this.filteredByDefault();
            if(defaultArr){
                const defaultObj = defaultArr.length > 0 ? defaultArr[0] : null;
                if(defaultObj){
                    this.activeQuestionForm(defaultObj, false);
                }
            }
            
            return data;
        },

        async getQuestionForm(formSeq:number):Promise<QuestionFormModel>{
            const data = await QuestionnaireApi.getQuestionForm(formSeq);
            if (!data || data.deleteFlag) {
                this.questionForm = null;
                throw new Error("삭제된 설문지");
            }
            this.activeQuestionForm(data, false);
            return data;
        },

         /** 파트 타입 그룹 가져오기 */
        filteredByDefault() {
            return this.questionForms?.filter( item => item.defaultFlag);
        },

        async getQuestionForms(clinicSeq:number, doctorSeq:number):Promise<Array<QuestionFormModel>>{

            const data = await QuestionnaireApi.getQuestionFormList(clinicSeq, doctorSeq);
            this.questionForms = [];

            //useFlag를 먼저 탐색해서 있는지 체크
            const find = this.findData(data, "useFlag");
            //useFlag값이 없으면, default가 있는지 체크
            if(!find){
                this.findData(data, "defaultFlag");
            }

            this.questionForms = data;
            return data;
        },
        
        async setQuestionFormDefault(value:DoctorModel){
            const data = await QuestionnaireApi.setQuestionFormDefault(value);
            return data;
        },

        async setQuestionFormDefaultForClinic(value:QuestionModel){
            const data = await QuestionnaireApi.setQuestionFormDefaultForClinic(value);
            return data;
        },
        async addQuestionAnswers(value:QuestionAnswerModel){
            const data = await QuestionnaireApi.addQuestionAnswers(value);
            return data;
        },
        
    }
})
