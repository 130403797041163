<template>
  <div class="pagination" :class="align">
    <button 
      :disabled="!isPrevButton" 
      class="page-prev" 
      @click="onClickPrev()"
    >
      <BaseIcon iconName="prev" />
    </button>
    <button 
      v-for="button in buttons" 
      :key="button"
      class="page-item"
      :disabled="!button.page || button.active"
      :class="{active: button.active}"
      @click="button.page && onClickNumber(button.page)"
    >
      {{ button.page ? button.page : "..." }}
    </button>
    <button 
      :disabled="!isNextButton" 
      class="page-next"
      @click="onClickNext()"
    >
      <BaseIcon iconName="next" />
    </button>
  </div>
</template>

<script setup>

import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  modelValue: Number,
  totalRows: Number,
  perPage: Number,
  maxLength: Number,
  align: String
});

const emit = defineEmits([
  'update:modelValue'
]);


const buttons = computed(() => {
  const { modelValue, totalRows, perPage, maxLength } = props;

  const NUMBER_LENGTH = maxLength || 7;  // 최소 5이상 홀수로만 가능
  const MINIMUM_LENGTH = 5;

  if(NUMBER_LENGTH < MINIMUM_LENGTH || NUMBER_LENGTH % 2 === 0) {
    return console.error("Paginatioin must be at least 5 or odd.");
  }

  const pages = Math.ceil(totalRows / perPage);
  const minPaginatioin = (NUMBER_LENGTH - MINIMUM_LENGTH) / 2;
  const half = NUMBER_LENGTH - Math.ceil(NUMBER_LENGTH / 2);
  const center = half + Math.floor((NUMBER_LENGTH - MINIMUM_LENGTH) / 2);
  const pageArr = [];

  if(pages > NUMBER_LENGTH){

    if(modelValue >= pages - half || modelValue > NUMBER_LENGTH - half && modelValue < pages - half){
      pageArr.push({ page: 1, active: false });
      pageArr.push({ page: null, active: false });
    }

    if(modelValue >= pages - half){
      for (let i = pages - center; i <= pages; i++) {
        pageArr.push({ page: i, active: isActive(i) });
      }
    }

    if(modelValue > NUMBER_LENGTH - half && modelValue < pages - half){
      for (let i = modelValue - minPaginatioin; i <= modelValue + minPaginatioin; i++) {
        pageArr.push({ page: i, active: isActive(i) });
      }
    }

    if(modelValue < NUMBER_LENGTH - (half - 1)){
      for (let i = 1; i <= NUMBER_LENGTH - 2; i++) {
        pageArr.push({ page: i, active: isActive(i) });
      }
    }

    if(modelValue < NUMBER_LENGTH - (half - 1) || modelValue > NUMBER_LENGTH - half && modelValue < pages - half){
      pageArr.push({ page: null, active: false });
      pageArr.push({ page: pages, active: false });
    }
   
  } else {
    for (let i = 1; i <= pages; i++) {
      pageArr.push({ page: i, active: isActive(i) });
    }
  }
  
  return pageArr;
});

const isActive = (num) => {
  return props.modelValue === num;
}

const onClickPrev = () => {
  if (isPrevButton.value) {
    emit('update:modelValue', props.modelValue - 1);
  }
}

const onClickNext = () => {
  if (isNextButton.value) {
    emit('update:modelValue', props.modelValue + 1);
  }
}

const onClickNumber = (pageNumber) => {
  emit("update:modelValue", pageNumber);
}

const isPrevButton = computed(() => {
  return props.modelValue > 1;
})

const isNextButton = computed(() => {
  return props.modelValue < Math.ceil(props.totalRows / props.perPage);
});
</script>

<style lang="scss" scoped>
.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  &.left {
    justify-content: flex-start !important;
  }
  &.right {
    justify-content: flex-end !important;
  }
  &.fill {
    button {
      width: 100%;
    }
  }
  button {
    flex: none;
    width: 32px;
    height: 32px;
    &.active,
    &:not(:disabled):hover {
      color: white;
      background-color: var(--color-primary);
      border-radius: 32px;
      overflow: hidden;
    }
  }
}
.page-prev,
.page-next {
  &:disabled {
    color: var(--font-color-secondary);
  }
}
.page-prev {
  margin-right: 15px;
}
.page-next {
  margin-left: 15px;
}
.page-item {
  margin: 0 3px;
  font-size: 16px;
}
</style>