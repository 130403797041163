const handler = (e: PointerEvent, el:HTMLElement, binding: {value: () => void}) => {
  if(el && !el.contains(e.target as Node) && !e.defaultPrevented) {
    binding.value();
  }
}

let onClick:any = null;

const ClickOutside = {
  mounted(el: HTMLElement, binding: {value: () => void}) {
    onClick = (e: Event) => handler(e as PointerEvent, el, binding);
    document.addEventListener("click", onClick, true);
  },
  unmounted() {
    document.removeEventListener("click", onClick, true);
  }
}

export default ClickOutside;