<template>
  <BaseLayout>
    <router-view v-slot="{ Component }">
      <transition name="page">
        <component :is="Component" />
      </transition>
    </router-view>
    <BaseToast />
  </BaseLayout>
</template>

<script setup>

//import : vue
import router from '@/router';
import { onBeforeMount } from "vue";

//import : lib
import _ from "lodash";
// import { installFcm } from "@/plugin/fcm/firebaseUtils"

//import : component
import BaseLayout from '@/layouts/BaseLayout';
import BaseToast from '@/components/common/base/BaseToast';

//import : store
import { useAuthStore } from "@/stores/store.auth";
import { useMainStore } from "@/stores";

//store
const { userAuth } = useAuthStore();
const { applyTheme } = useMainStore();


onBeforeMount( async () => {
  await initData();
  // await setFcmMesseage();
  // installFcm();
});

/**
 * 초기 데이터세팅
 */
const initData = async() => {
  const localTheme = localStorage.getItem("theme");
  if(!localTheme){
    localStorage.setItem("theme", "dark");
    applyTheme("dark");
  } else {
    applyTheme(localTheme);
  }

  if(_.isEmpty(userAuth)){
    router.push('/login');
  }
}

</script>

