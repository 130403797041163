import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDVKmM1dpsb-RP-vISOS4Ozz6Wj6e1yAcY",
    authDomain: "mapseries-361701.firebaseapp.com",
    projectId: "mapseries-361701",
    storageBucket: "mapseries-361701.appspot.com",
    messagingSenderId: "54895256484",
    appId: "1:54895256484:web:dc463b06d8e99c76281731",
    measurementId: "G-CXKZB7HFGN"
};

export const setFireBase = () => {
  // Firebase 앱 초기화
  const firebaseApp = initializeApp(firebaseConfig);

  const messaging = getMessaging(firebaseApp);

  return messaging;
}

