import App from './App.vue'
import router from './router';
import { createApp } from 'vue'
import { createPinia } from 'pinia';
import BaseAvatar from '@/components/common/base/BaseAvatar.vue';
import BaseDropdown from '@/components/common/base/BaseDropdown.vue';
import BaseEmpty from '@/components/common/base/BaseEmpty.vue';
import BaseIcon from '@/components/common/base/BaseIcon.vue';
import BaseLoading from '@/components/common/base/BaseLoading.vue';
import BaseModal from '@/components/common/base/BaseModal.vue';
import BasePagination from '@/components/common/base/BasePagination.vue';
import BaseSlider from '@/components/common/base/BaseSlider.vue';
import ClickOutside from '@/common/directives/click-outside';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import dayjs from 'dayjs';
import { getDateFormat, getDateFormatWithTime, t } from "@/common/util";
import 'bootstrap';
import '@/assets/scss/app.scss';
import 'cropperjs/dist/cropper.css';
import { i18n } from '@/locale/i18n';
import Vue3Lottie from 'vue3-lottie';
import 'v-calendar/style.css';

import "@/assets/scss/app.scss"

const app = createApp(App);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hostname = window.location.hostname;

app.config.globalProperties.dayjs = dayjs;
app.config.globalProperties.getDateFormat = getDateFormat;
app.config.globalProperties.getDateFormatWithTime = getDateFormatWithTime;
app.config.globalProperties.t = t;
app.component("BaseAvatar", BaseAvatar);
app.component("BaseDropdown", BaseDropdown);
app.component("BaseEmpty", BaseEmpty);
app.component("BaseIcon", BaseIcon);
app.component("BaseLoading", BaseLoading);
app.component("BaseModal", BaseModal);
app.component("BasePagination", BasePagination);
app.component("BaseSlider", BaseSlider);
app.component("VCalendar",Calendar);
app.component("VDatePicker",DatePicker);

app.directive("click-outside", ClickOutside);
app.use(createPinia());
app.use(setupCalendar, {})
app.use(router);
app.use(Vue3Lottie);


app.use(i18n);
app.mount('#app')
