import { t } from "@/common/util";

export const getUserData = () =>{
    try{
        return JSON.parse(localStorage.getItem('userData'));
    }catch(error){
        return null;
    }
}

export const getActionStatus = (code) => {
    const patientStatus = {
        ADDSPOT:t("common.ADDSPOT"),
        ADDTBP:t("common.ADDTBP"),
        DELSPOT:t("common.DELSPOT"),
        PROCEDURE:t("common.PROCEDURE"),
        CLINICALDX:t("common.CLINICALDX"),
        PATHOLOGYDX:t("common.PATHOLOGYDX"),
        ADDDETAIL:t("common.ADDDETAIL"),
        MAPTBP:t("common.MAPTBP"),
        ADDOVERVIEW:t("common.ADDOVERVIEW")
    };

    return patientStatus[code];
}

export class Waiter {
    constructor() {
        this.init();
    }

    init() {
        this.waiting = new Promise((resolve) => {
            this.end = () => {
                resolve();
                this.init();
            }
        })
    }
}