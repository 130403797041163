import axios, { AxiosError } from "axios";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  timeout: 80000,
  withCredentials: false,
  headers: {
      "Content-Type": "application/json",
      "Authorization": "",
  },
  
});

http.interceptors.request.use(
  async (config) => {
    const useAuth = JSON.parse(localStorage.getItem("userAuth") || '{}')
    if (useAuth) {
      const header = 'Bearer '+useAuth?.accessToken;
      config.headers["Authorization"] = header;
    }
    return config;
  },
  (error) => {
    const errData = {...error, error:true};
    errData.status = 999;
    return Promise.reject(errData);
  } 
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errData = {...error, error:true};
    const originalRequest = error.config;
    console.warn("http error", error, errData);

    //response가 없을때, 에러
    if(!error.response){
      if(error.code == "ERR_NETWORK"){
        errData.status = 601;
        errData.message = "Error : Network Error";
      }else{
        errData.status = 699;
        errData.message = "Error..";
      }
      return Promise.reject(error);
    //response가 있을때 에러
    }else if(error.response.status === 401 && error.response.data?.resultCode === "461"){
      console.warn( "requeired...");
      originalRequest._retry = true;
      const useAuth = JSON.parse(localStorage.getItem("userAuth") || '{}')

      if (useAuth) {
        return axios
          .post(process.env.VUE_APP_API_URI + "/auth/reissueAccessToken", 
            { refreshToken: useAuth.refreshToken })
          .then(async (res) => {
            if (res.status === 200) {
              const jsonAuth = JSON.stringify(res.data);
              localStorage.setItem("userAuth", jsonAuth);
              localStorage.setItem('expiryDate', ""+(new Date().getTime()+res.data.expiryDuration));
              console.log( "success");
              return http(originalRequest);        
            }
          })
          .catch((error) => {
            console.error("axios refresh error", error);
            router.push("/login");
          });
      }
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      router.push("/login");
      return Promise.reject(error);
    } else if (error.response.status === 500) {
      console.error("Internal Server Error	 :", error.response);
      errData.status = 500;
      errData.message = "Error : Internal Server Error";
    } else if (error.response.status === 502) {
      console.error("Bad Gateway	 :", error.response);
      errData.status = 502;
      errData.message = "Error : Bad Gateway";
    } else if (error.response.status === 404) {
      console.error("Error : Not Found :", error.config.url);
      errData.status = 404;
      errData.message = "Error : Not Found";
    }else if( error.response.status === 400 && error.message === "ACCOUNT_NOT_FOUND"){
      console.error("아이디나 패스워드가 틀렸습니다.");
      errData.status = 400;
      errData.message = "Error : failed login";
    }else if(error.response.status){
      console.error("그외의 에러", error.response.status);
      errData.status = error.response.status;
      errData.message = "error...";
    //알수없는 에러
    }else{
      console.error("알 수 없는 에러", error.response);
      errData.status = 9999;
      errData.message = "error....";
    }
    return Promise.reject(errData);
  } 
);

export const getErrorMsg = (error:unknown, msg:String) => {
  if(isAxiosError(error)){
    return {
      ...error,
      error:true,
      message:msg
    }
  }else{
    return {
      error:true,
      message:msg
    }
  }
}

const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
}

export default http;