<template>
  <div class="dropdown" v-click-outside="close">
    <button @click="toggle">
      <slot name="button" />
    </button>
    <div v-show="isMore" class="more-container">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const isMore = ref(false);

    const toggle = () => {
      isMore.value = !isMore.value;
    }
    const close = () => {
      isMore.value = false;
    }
    
    return {
      isMore,
      toggle,
      close
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
}
.more-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>