import http from "./http"
import { DoctorModel } from "@/types/type.doctor"

class DoctorApi{
    async getDoctors(clinicSeq:number):Promise<DoctorModel[]>{
        try{
            const response = await http.get(`/clinic/getDoctorListByClinicSeq?clinicSeq=${clinicSeq}`);
            return response.data;
        }catch (error){
            return Promise.reject(error);
        }
    }

    async getDoctor(doctorSeq:number):Promise<DoctorModel>{
        try{
            const response = await http.get(`/doctor/getDoctor?doctorSeq=${doctorSeq}`);
            return response.data;
        }catch (error){
            return Promise.reject(error);
        }
    }

    async updateDoctor(doctor:DoctorModel):Promise<DoctorModel>{
        try{
            const response = await http.post(`/doctor/updateDoctor`,doctor);
            return response.data;
        }catch (error){
            return Promise.reject(error);
        }
    }

    async updateDoctorPushKey(doctor:DoctorModel):Promise<DoctorModel>{
        try{
            const response = await http.post(`/doctor/updateDoctorPushKey`,doctor);
            return response.data;
        }catch (error){
            return Promise.reject(error);
        }
    }
}

export default new DoctorApi();